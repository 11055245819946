export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOAD_REQUEST = 'USER_LOAD_REQUEST';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS ';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST'
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const GET_REPOS = 'GET_REPOS';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const GET_JOBS_REQUEST = 'GET_JOBS_REQUEST'
export const GET_JOBS_SUCCESS = 'GET_JOBS_SUCCESS';
export const GET_JOB = 'GET_JOB';
export const GET_JOBS_BY_DISTRICT = 'GET_JOB_BY_DISTRICT';
export const JOB_ERROR = 'JOB_ERROR';
export const UPDATE_APPLIES = 'UPDATE_APPLIES';
export const DELETE_JOB = 'DELETE_JOB';
export const ADD_JOB = 'ADD_JOB';
export const ADD_COMMENT = 'ADD_COMMENT';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';

export const JobConstants = {
    DELETE_JOB_REQUEST: 'DELETE_JOB_REQUEST',
    DELETE_JOB_SUCCESS: 'DELETE_JOB_SUCCESS',
    DELETE_JOB_FAILURE: 'DELETE_JOB_FAILURE',

    GET_JOB_STATUS_REQUEST: "GET_JOB_STATUS_REQUEST",
    GET_JOB_STATUS_SUCCESS: "GET_JOB_STATUS_SUCCESS",
    GET_JOB_STATUS_FAILURE: "GET_JOB_STATUS_FAILURE"
}

//This is the way to declare the constants, make above later making the same pattern
export const CategoryConstants = {
    GET_ALL_CATEGORY_REQUEST: 'GET_ALL_CATEGORY_REQUEST',
    GET_ALL_CATEGORY_SUCCESS: 'GET_ALL_CATEGORY_SUCCESS',
    GET_ALL_CATEGORY_FAILURE: 'GET_ALL_CATEGORY_FAILURE',

    ADD_NEW_CATEGORY_REQUEST: 'ADD_NEW_CATEGORY_REQUEST',
    ADD_NEW_CATEGORY_SUCCESS: 'ADD_NEW_CATEGORY_SUCCESS',
    ADD_NEW_CATEGORY_FAILURE: 'ADD_NEW_CATEGORY_FAILURE',

    UPDATE_CATEGORIES_REQUEST: 'UPDATE_CATEGORIES_REQUEST',
    UPDATE_CATEGORIES_SUCCESS: 'UPDATE_CATEGORIES_SUCCESS',
    UPDATE_CATEGORIES_FAILURE: 'UPDATE_CATEGORIES_FAILURE',

    DELETE_CATEGORIES_REQUEST: 'DELETE_CATEGORIES_REQUEST',
    DELETE_CATEGORIES_SUCCESS: 'DELETE_CATEGORIES_SUCCESS',
    DELETE_CATEGORIES_FAILURE: 'DELETE_CATEGORIES_FAILURE',
}

export const ProductConstants = {
    GET_ALL_PRODUCTS_REQUEST: 'GET_ALL_PRODUCTS_REQUEST',
    GET_ALL_PRODUCTS_SUCCESS: 'GET_ALL_PRODUCTS_SUCCESS',
    GET_ALL_PRODUCTS_FAILURE: 'GET_ALL_PRODUCTS_FAILURE',
    GET_PRODUCTS_BY_SLUG: 'GET_PRODUCTS_BY_SLUG',

    GET_PRODUCT_PAGE_REQUEST: "GET_PRODUCT_PAGE_REQUEST",
    GET_PRODUCT_PAGE_SUCCESS: "GET_PRODUCT_PAGE_SUCCESS",
    GET_PRODUCT_PAGE_FAILURE: "GET_PRODUCT_PAGE_FAILURE",

    GET_PRODUCT_DETAILS_BY_ID_REQUEST: "GET_PRODUCT_DETAILS_BY_ID_REQUEST",
    GET_PRODUCT_DETAILS_BY_ID_SUCCESS: "GET_PRODUCT_DETAILS_BY_ID_SUCCESS",
    GET_PRODUCT_DETAILS_BY_ID_FAILURE: "GET_PRODUCT_DETAILS_BY_ID_FAILURE",

    DELETE_PRODUCT_BY_ID_REQUEST: "DELETE_PRODUCT_BY_ID_REQUEST",
    DELETE_PRODUCT_BY_ID_SUCCESS: "DELETE_PRODUCT_BY_ID_SUCCESS",
    DELETE_PRODUCT_BY_ID_FAILURE: "DELETE_PRODUCT_BY_ID_FAILURE",

    ADD_PRODUCT_REQUEST: "ADD_PRODUCT_REQUEST",
    ADD_PRODUCT_SUCCESS: "ADD_PRODUCT_SUCCESS",
    ADD_PRODUCT_FAILURE: "ADD_PRODUCT_FAILURE"
}

export const initialDataConstants = {
    GET_ALL_INITIAL_DATA_REQUEST: 'GET_ALL_INITIAL_DATA_REQUEST',
    GET_ALL_INITIAL_DATA_SUCCESS: 'GET_ALL_INITIAL_DATA_SUCCESS',
    GET_ALL_INITIAL_DATA_FAILURE: 'GET_ALL_INITIAL_DATA_FAILURE'
}

export const pageConstants = {
    CREATE_PAGE_REQUEST: "CREATE_PAGE_REQUEST",
    CREATE_PAGE_SUCCESS: "CREATE_PAGE_SUCCESS",
    CREATE_PAGE_FAILURE: "CREATE_PAGE_FAILURE",
};

export const CartConstants = {
    ADD_TO_CART_REQUEST: "ADD_TO_CART_REQUEST",
    ADD_TO_CART_SUCCESS: "ADD_TO_CART_SUCCESS",
    ADD_TO_CART_FAILURE: "ADD_TO_CART_FAILURE",

    RESET_CART: "RESET_CART",

    REMOVE_CART_ITEM_REQUEST: "REMOVE_CART_ITEM_REQUEST",
    REMOVE_CART_ITEM_SUCCESS: "REMOVE_CART_ITEM_SUCCESS",
    REMOVE_CART_ITEM_FAILURE: "REMOVE_CART_ITEM_FAILURE",
};

export const AddressConstants = {
    GET_USER_ADDRESS_REQUEST: "GET_USER_ADDRESS_REQUEST",
    GET_USER_ADDRESS_SUCCESS: "GET_USER_ADDRESS_SUCCESS",
    GET_USER_ADDRESS_FAILURE: "GET_USER_ADDRESS_FAILURE",

    ADD_USER_ADDRESS_REQUEST: "ADD_USER_ADDRESS_REQUEST",
    ADD_USER_ADDRESS_SUCCESS: "ADD_USER_ADDRESS_SUCCESS",
    ADD_USER_ADDRESS_FAILURE: "ADD_USER_ADDRESS_FAILURE"
};

export const OrderConstants = {
    ADD_USER_ORDER_REQUEST: "ADD_USER_ORDER_REQUEST",
    ADD_USER_ORDER_SUCCESS: "ADD_USER_ORDER_SUCCESS",
    ADD_USER_ORDER_FAILURE: "ADD_USER_ORDER_FAILURE",

    GET_USER_ORDER_REQUEST: "GET_USER_ORDER_REQUEST",
    GET_USER_ORDER_SUCCESS: "GET_USER_ORDER_SUCCESS",
    GET_USER_ORDER_FAILURE: "GET_USER_ORDER_FAILURE",

    GET_USER_ORDER_DETAILS_REQUEST: "GET_USER_ORDER_DETAILS_REQUEST",
    GET_USER_ORDER_DETAILS_SUCCESS: "GET_USER_ORDER_DETAILS_SUCCESS",
    GET_USER_ORDER_DETAILS_FAILURE: "GET_USER_ORDER_DETAILS_FAILURE",

    GET_CUSTOMER_ORDER_REQUEST: "GET_CUSTOMER_ORDER_REQUEST",
    GET_CUSTOMER_ORDER_SUCCESS: "GET_CUSTOMER_ORDER_SUCCESS",
    GET_CUSTOMER_ORDER_FAILURE: "GET_CUSTOMER_ORDER_FAILURE",

    UPDATE_CUSTOMER_ORDER_REQUEST: "UPDATE_CUSTOMER_ORDER_REQUEST",
    UPDATE_CUSTOMER_ORDER_SUCCESS: "UPDATE_CUSTOMER_ORDER_SUCCESS",
    UPDATE_CUSTOMER_ORDER_FAILURE: "UPDATE_CUSTOMER_ORDER_FAILURE",
};

export const SectorConstants = {
    ADD_SECTOR_REQUEST: "ADD_SECTOR_REQUEST",
    ADD_SECTOR_SUCCESS: "ADD_SECTOR_SUCCESS",
    ADD_SECTOR_FAILURE: "ADD_SECTOR_FAILURE",

    GET_SECTORS_REQUEST: "GET_SECTORS_REQUEST",
    GET_SECTORS_SUCCESS: "GET_SECTORS_SUCCESS",
    GET_SECTORS_FAILURE: "GET_SECTORS_FAILURE",
};

export const ServiceConstants = {
    ADD_SERVICE_REQUEST: "ADD_SERVICE_REQUEST",
    ADD_SERVICE_SUCCESS: "ADD_SERVICE_SUCCESS",
    ADD_SERVICE_FAILURE: "ADD_SERVICE_FAILURE",

    GET_SERVICES_REQUEST: "GET_SERVICES_REQUEST",
    GET_SERVICES_SUCCESS: "GET_SERVICES_SUCCESS",
    GET_SERVICES_FAILURE: "GET_SERVICES_FAILURE",

    ADD_SERVICE_FIELDS_REQUEST: "ADD_SERVICE_FIELDS_REQUEST",
    ADD_SERVICE_FIELDS_SUCCESS: "ADD_SERVICE_FIELDS_SUCCESS",
    ADD_SERVICE_FIELDS_FAILURE: "ADD_SERVICE_FIELDS_FAILURE",

    GET_SERVICE_FIELDS_REQUEST: "GET_SERVICE_FIELDS_REQUEST",
    GET_SERVICE_FIELDS_SUCCESS: "GET_SERVICE_FIELDS_SUCCESS",
    GET_SERVICE_FIELDS_FAILURE: "GET_SERVICE_FIELDS_FAILURE",
  };

export const ProfessionalConstants = {
    ADD_PROFESSIONAL_REQUEST: "ADD_PROFESSIONAL_REQUEST",
    ADD_PROFESSIONAL_SUCCESS: "ADD_PROFESSIONAL_SUCCESS",
    ADD_PROFESSIONAL_FAILURE: "ADD_PROFESSIONAL_FAILURE",

    GET_PROFESSIONALS_REQUEST: "GET_PROFESSIONALS_REQUEST",
    GET_PROFESSIONALS_SUCCESS: "GET_PROFESSIONALS_SUCCESS",
    GET_PROFESSIONALS_FAILURE: "GET_PROFESSIONALS_FAILURE",

    GET_PROFESSIONAL_STATUS_REQUEST: "GET_PROFESSIONAL_STATUS_REQUEST",
    GET_PROFESSIONAL_STATUS_SUCCESS: "GET_PROFESSIONAL_STATUS_SUCCESS",
    GET_PROFESSIONAL_STATUS_FAILURE: "GET_PROFESSIONAL_STATUS_FAILURE",

    UPDATE_PROFESSIONAL_REQUEST: "UPDATE_PROFESSIONAL_REQUEST",
    UPDATE_PROFESSIONAL_SUCCESS: "UPDATE_PROFESSIONAL_SUCCESS",
    UPDATE_PROFESSIONAL_FAILURE: "UPDATE_PROFESSIONAL_FAILURE",

    GET_PROFESSIONAL_REQUEST: "GET_PROFESSIONAL_REQUEST",
    GET_PROFESSIONAL_SUCCESS: "GET_PROFESSIONAL_SUCCESS",
    GET_PROFESSIONAL_FAILURE: "GET_PROFESSIONAL_FAILURE",

    ADD_PROFESSIONAL_DETAILS_REQUEST: "ADD_PROFESSIONAL_DETAILS_REQUEST",
    ADD_PROFESSIONAL_DETAILS_SUCCESS: "ADD_PROFESSIONAL_DETAILS_SUCCESS",
    ADD_PROFESSIONAL_DETAILS_FAILURE: "ADD_PROFESSIONAL_DETAILS_FAILURE",

    UPDATE_PROF_AVAILABILITY_REQUEST: "UPDATE_PROF_AVAILABILITY_REQUEST",
    UPDATE_PROF_AVAILABILITY_SUCCESS: "UPDATE_PROF_AVAILABILITY_SUCCESS",
    UPDATE_PROF_AVAILABILITY_FAILURE: "UPDATE_PROF_AVAILABILITY_FAILURE"
}

export const SubscriptionConstants = {
    ADD_SUBSCRIPTION_REQUEST: "ADD_SUBSCRIPTION_REQUEST",
    ADD_SUBSCRIPTION_SUCCESS: "ADD_SUBSCRIPTION_SUCCESS",
    ADD_SUBSCRIPTION_FAILURE: "ADD_SUBSCRIPTION_FAILURE",

    GET_SUBSCRIPTIONS_REQUEST: "GET_SUBSCRIPTIONS_REQUEST",
    GET_SUBSCRIPTIONS_SUCCESS: "GET_SUBSCRIPTIONS_SUCCESS",
    GET_SUBSCRIPTIONS_FAILURE: "GET_SUBSCRIPTIONS_FAILURE",

    TOGGLE_SUBSCRIPTION_ENABLE_REQUEST: "TOGGLE_SUBSCRIPTION_ENABLE_REQUEST",
    TOGGLE_SUBSCRIPTION_ENABLE_SUCCESS: "TOGGLE_SUBSCRIPTION_ENABLE_SUCCESS",
    TOGGLE_SUBSCRIPTION_ENABLE_FAILURE: "TOGGLE_SUBSCRIPTION_ENABLE_FAILURE",

    UPDATE_SUBSCRIPTION_PRICE_REQUEST: "UPDATE_SUBSCRIPTION_PRICE_REQUEST",
    UPDATE_SUBSCRIPTION_PRICE_SUCCESS: "UPDATE_SUBSCRIPTION_PRICE_SUCCESS",
    UPDATE_SUBSCRIPTION_PRICE_FAILURE: "UPDATE_SUBSCRIPTION_PRICE_FAILURE",
}

export const JobSectorConstants = {
    ADD_JOB_SECTOR_REQUEST: "ADD_JOB_SECTOR_REQUEST",
    ADD_JOB_SECTOR_SUCCESS: "ADD_JOB_SECTOR_SUCCESS",
    ADD_JOB_SECTOR_FAILURE: "ADD_JOB_SECTOR_FAILURE",

    GET_JOB_SECTORS_REQUEST: "GET_JOB_SECTORS_REQUEST",
    GET_JOB_SECTORS_SUCCESS: "GET_JOB_SECTORS_SUCCESS",
    GET_JOB_SECTORS_FAILURE: "GET_JOB_SECTORS_FAILURE",
};

export const JobCategoryConstants = {
    ADD_JOB_CATEGORY_REQUEST: "ADD_JOB_CATEGORY_REQUEST",
    ADD_JOB_CATEGORY_SUCCESS: "ADD_JOB_CATEGORY_SUCCESS",
    ADD_JOB_CATEGORY_FAILURE: "ADD_JOB_CATEGORY_FAILURE",

    GET_JOB_CATEGORY_REQUEST: "GET_JOB_CATEGORY_REQUEST",
    GET_JOB_CATEGORY_SUCCESS: "GET_JOB_CATEGORY_SUCCESS",
    GET_JOB_CATEGORY_FAILURE: "GET_JOB_CATEGORY_FAILURE",
};

export const JobSubscriptionConstants = {
    ADD_JOB_SUBSCRIPTION_REQUEST: "ADD_JOB_SUBSCRIPTION_REQUEST",
    ADD_JOB_SUBSCRIPTION_SUCCESS: "ADD_JOB_SUBSCRIPTION_SUCCESS",
    ADD_JOB_SUBSCRIPTION_FAILURE: "ADD_JOB_SUBSCRIPTION_FAILURE",

    GET_JOB_SUBSCRIPTIONS_REQUEST: "GET_JOB_SUBSCRIPTIONS_REQUEST",
    GET_JOB_SUBSCRIPTIONS_SUCCESS: "GET_JOB_SUBSCRIPTIONS_SUCCESS",
    GET_JOB_SUBSCRIPTIONS_FAILURE: "GET_JOB_SUBSCRIPTIONS_FAILURE",
}

export const PhonePeConstants = {
    PHONEPE_REQUEST: "PHONEPE_REQUEST",
    PHONEPE_SUCCESS: "PHONEPE_SUCCESS",
    PHONEPE_FAILURE: "PHONEPE_FAILURE",

    PHONEPE_CHECK_STATUS_REQUEST: "PHONEPE_CHECK_STATUS_REQUEST",
    PHONEPE_CHECK_STATUS_SUCCESS: "PHONEPE_CHECK_STATUS_SUCCESS",
    PHONEPE_CHECK_STATUS_FAILURE: "PHONEPE_CHECK_STATUS_FAILURE",
}

export const CheckReferralConstants = {
    UPDATE_REFERRAL_CODE: 'UPDATE_REFERRAL_CODE',
    CHECK_REFERRAL_CODE_REQUEST: 'CHECK_REFERRAL_CODE_REQUEST',
    CHECK_REFERRAL_CODE_SUCCESS: 'CHECK_REFERRAL_CODE_SUCCESS',
    CHECK_REFERRAL_CODE_FAILURE: 'CHECK_REFERRAL_CODE_FAILURE'
}

export const StateConstants = {
    GET_STATES_REQUEST: "GET_STATES_REQUEST",
    GET_STATES_SUCCESS: "GET_STATES_SUCCESS",
    GET_STATES_FAILURE: "GET_STATES_FAILURE"
}

export const DistrictConstants = {
    GET_DISTRICTS_REQUEST: "GET_DISTRICTS_REQUEST",
    GET_DISTRICTS_SUCCESS: "GET_DISTRICTS_SUCCESS",
    GET_DISTRICTS_FAILURE: "GET_DISTRICTS_FAILURE"
}

