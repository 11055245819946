import { ProfessionalConstants } from './types';
import { setAlert } from './alert';
import backendAxios from '../utils/backendAxios';

//Admin
export const addProfessional = (newProfessional) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ProfessionalConstants.ADD_PROFESSIONAL_REQUEST });
            const res = await backendAxios.post('/api/workerCategory', newProfessional, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            if (res.status === 201) {
                dispatch({ type: ProfessionalConstants.ADD_PROFESSIONAL_SUCCESS });
                const { professional } = res.data;
                dispatch(getProfessionalStatus('', '', professional?._id));
            } else {
                const { error } = res.data;
                dispatch({
                    type: ProfessionalConstants.ADD_PROFESSIONAL_FAILURE,
                    payload: { error }
                });
            }
        } catch (errors) {
            console.log(errors)
            dispatch(setAlert('Adding Professional Failed', 'danger'));
            dispatch({
                type: ProfessionalConstants.ADD_PROFESSIONAL_FAILURE,
                payload: { errors }
            });
        }
    };
};

// Update status of professional as applied, eligible, ineligible, blocked
export const updateProfessional = (payload) => {
    return async (dispatch) => {
        try {
            console.log(payload)
            dispatch({ type: ProfessionalConstants.UPDATE_PROFESSIONAL_REQUEST });
            const res = await backendAxios.post(`/api/workerCategory/update`, payload);
            console.log("res", res)
            if (res.status === 201) {
                dispatch({ type: ProfessionalConstants.UPDATE_PROFESSIONAL_SUCCESS });
                dispatch(getProfessionals());
            } else {
                const { error } = res.data;
                dispatch({
                    type: ProfessionalConstants.UPDATE_PROFESSIONAL_FAILURE,
                    payload: { error }
                });
            }
        } catch (error) {
            console.log(error)
            const err = error.response.data.errors;
            err.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            dispatch({
                type: ProfessionalConstants.UPDATE_PROFESSIONAL_FAILURE,
                payload: { error }
            });
        }
    };
};

export const getProfessionals = (queryParams) => {
    return async dispatch => {
        try {
            dispatch({ type: ProfessionalConstants.GET_PROFESSIONALS_REQUEST });
            const res = await backendAxios.get(`/api/workerCategory`, {
                params: queryParams
            });
            if (res.status === 201) {
                const { professionals } = res.data;
                dispatch({
                    type: ProfessionalConstants.GET_PROFESSIONALS_SUCCESS,
                    payload: { professionals }
                });
            }
            else {
                const { error } = res.data;
                dispatch({
                    type: ProfessionalConstants.GET_PROFESSIONALS_FAILURE,
                    payload: { error }
                });
            }
        } catch (error) {
            dispatch(setAlert(error.msg, 'danger'))
            dispatch({
                type: ProfessionalConstants.GET_PROFESSIONALS_FAILURE,
                payload: { error },
            });
        }
    }
}

export const getProfessionalStatus = (serviceId, choiceId, profId, mobile) => {
    return async dispatch => {
        try {
            dispatch({ type: ProfessionalConstants.GET_PROFESSIONAL_STATUS_REQUEST });
            const res = await backendAxios.get(`/api/workerCategory/status`, {
                params: {
                    serviceId,
                    choiceId,
                    profId,
                    mobile
                }
            });
            if (res.status === 201) {
                const { professional } = res.data;
                dispatch({
                    type: ProfessionalConstants.GET_PROFESSIONAL_STATUS_SUCCESS,
                    payload: { professional }
                });
            }
            else {
                const { error } = res.data;
                dispatch({
                    type: ProfessionalConstants.GET_PROFESSIONAL_STATUS_FAILURE,
                    payload: { error }
                });
            }
        } catch (error) {
            dispatch(setAlert(error.msg, 'danger'))
            dispatch({
                type: ProfessionalConstants.GET_PROFESSIONAL_STATUS_FAILURE,
                payload: { error },
            });
        }
    }
}

export const getProfessionalById = (profId) => {
    return async dispatch => {
        try {
            dispatch({ type: ProfessionalConstants.GET_PROFESSIONAL_REQUEST });
            const res = await backendAxios.get(`/api/workerCategory/${profId}`);
            if (res.status === 201) {
                const { professional } = res.data;
                dispatch({
                    type: ProfessionalConstants.GET_PROFESSIONAL_SUCCESS,
                    payload: { professional }
                });
            }
            else {
                const { error } = res.data;
                dispatch({
                    type: ProfessionalConstants.GET_PROFESSIONAL_FAILURE,
                    payload: { error }
                });
            }
        } catch (error) {
            dispatch(setAlert(error.msg, 'danger'))
            dispatch({
                type: ProfessionalConstants.GET_PROFESSIONAL_FAILURE,
                payload: { error },
            });
        }
    }
}

export const addProfessionalDetails = (professionalDetailsObj, profId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ProfessionalConstants.ADD_PROFESSIONAL_DETAILS_REQUEST });
            const res = await backendAxios.post(`/api/workerCategory/${profId}/properties`, professionalDetailsObj, 
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }
            );
            if (res.status === 201) {
                dispatch({ type: ProfessionalConstants.ADD_PROFESSIONAL_DETAILS_SUCCESS });
                const { professional } = res.data;
                dispatch(getProfessionalStatus('', '', professional?._id));
            } else {
                const { error } = res.data;
                dispatch({
                    type: ProfessionalConstants.ADD_PROFESSIONAL_DETAILS_FAILURE,
                    payload: { error }
                });
            }
        } catch (errors) {
            console.log(errors)
            dispatch(setAlert('Adding Professional Details Failed', 'danger'));
            dispatch({
                type: ProfessionalConstants.ADD_PROFESSIONAL_FAILURE,
                payload: { errors }
            });
        }
    };
};

// Set Professional availability
export const setProfAvailability = (availabilityObj, profId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ProfessionalConstants.UPDATE_PROF_AVAILABILITY_REQUEST });
            const res = await backendAxios.post(`/api/workerCategory/${profId}/availability`, availabilityObj);
            if (res.status === 201) {
                dispatch({ type: ProfessionalConstants.UPDATE_PROF_AVAILABILITY_SUCCESS });
                dispatch(getProfessionalStatus());
            } else {
                const { error } = res.data;
                dispatch({
                    type: ProfessionalConstants.UPDATE_PROF_AVAILABILITY_FAILURE,
                    payload: { error }
                });
            }
        } catch (error) {
            console.log(error)
            const err = error.response.data.errors;
            err.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            dispatch({
                type: ProfessionalConstants.UPDATE_PROF_AVAILABILITY_FAILURE,
                payload: { error }
            });
        }
    };
};