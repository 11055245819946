// Function to check admin salesPerson role
const checkAdminSalesPersonRole = (role) => {
   return role === "admin" || role === "salesPerson"
};

const checkAdminRole = (role) => {
   return role === "admin"
};

const checkUserRole = (role) => {
   return role === "user"
}

function executeForAdminOrSupportRoles(role) {
   return role === "admin" || role === "customerSupport"
}

function executeForAdminOrSalesPersonOrSupportRoles(role) {
   return role === "admin" || role === "salesPerson" ||  role === "customerSupport"
}

export {
   checkAdminSalesPersonRole, 
   checkAdminRole, 
   checkUserRole, 
   executeForAdminOrSupportRoles,
   executeForAdminOrSalesPersonOrSupportRoles
}
