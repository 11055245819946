import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addAddress } from "../../../actions/address";
import PhoneInput from 'react-phone-input-2'
import Pincode from "../../profile-forms/Pincode";
import getCompleteMobileNumberWithPlus from "../../../utils/MobileNumber";
import { checkUserRole } from "../../../utils/checkRole";

/**
 * @author
 * @function AddressForm
 **/

const AddressForm = (props) => {

  const auth = useSelector((state) => state.auth);
  const userAddress = useSelector((state) => state.address);

  /* Input Fields */
  const initialData = props.initialData
  const [formData, setFormData] = useState({
    name: initialData?.name ? initialData.name : (checkUserRole(auth?.user?.role)? auth?.user?.name: ''),
    mobile: initialData?.mobile ? initialData.mobile : (checkUserRole(auth?.user?.role)? auth?.user?.mobile: ''),
    completeAdd: initialData?.manualAddress?.completeAdd ? initialData.manualAddress.completeAdd : "",
    buildingDetails: initialData?.manualAddress?.buildingDetails ? initialData.manualAddress.buildingDetails : "",
    floorDetails: initialData?.manualAddress?.floorDetails ? initialData.manualAddress.floorDetails : "",
    landmark: '',
  });

  const { name, mobile, completeAdd, buildingDetails, floorDetails, landmark } = formData;

  const [postOffice, setPostOffice] = useState({
    Pincode: initialData ? initialData.pincodeData.pincode : "",
    Name: initialData ? initialData.pincodeData.poName : "",
    Block: initialData ? initialData.pincodeData.cityBlock : "",
    District: initialData ? initialData.pincodeData.district : "",
    State: initialData ? initialData.pincodeData.stateName : ""
  })

  const [pincode, setPincode] = useState('')

  const [submitFlag, setSubmitFlag] = useState(false);
  const [addressId, setAddressId] = useState(initialData ? initialData._id : "");


  const dispatch = useDispatch();


  const onAddressSubmit = (e) => {
    e.preventDefault();

    // Validate required fields
    // if (!name || !postOffice || !postOffice.pincode || !postOffice.placename) { // Rapid API
      if (!name || !postOffice || !postOffice.Pincode || !postOffice.Name || !mobile) { // Indian API
      // Handle validation error, for example, show an alert
      alert("Please fill in all required fields.");
      return;
    }
    const newAddress = {
      address: {
        name,
        mobile: mobile,
        country: 'India',
        pincodeData: {
          // Data from Rapid API
          // pincode: postOffice.pincode,
          // poName: postOffice.placename,
          // cityBlock: postOffice.taluk,
          // district: postOffice.districtname,
          // stateName: postOffice.statename,


          // Data from Indian API
          pincode: postOffice.Pincode,
          poName: postOffice.Name ? postOffice.Name : '',
          cityBlock: postOffice.Block ? postOffice.Block : '',
          district: postOffice.District ? postOffice.District : '',
          stateName: postOffice.State ? postOffice.State : ''
        },
        manualAddress: {
          completeAdd,
          buildingDetails,
          floorDetails
        },
        landmark,
        addressInUse: true
      },
    };
    if (addressId) {
      newAddress.address._id = addressId;
    }
    dispatch(addAddress(newAddress));

    // To disable the address form rendered
    if (props.setNewAddress) {
      props.setNewAddress(false)
    }
    setSubmitFlag(true);
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const renderAddressForm = () => {
    return (
      <>
        <div className="rounded-md p-1 w-full">
          <div className="mb-2">
            <label
              htmlFor="reachablePerson"
              className="block text-sm font-medium text-gray-600"
            >
              Reachable Person
            </label>
            <input
              type='text'
              className="mt-1 p-2 w-full border rounded-md"
              placeholder='Name*'
              name='name'
              value={name}
              onChange={onChange}
              required
            />
          </div>

          <div className="mb-2">
            <label
              htmlFor="reachableNumber"
              className="block text-sm font-medium text-gray-600"
            >
              Reachable Number
            </label>

            <PhoneInput
              onlyCountries={['in']}
              country={"in"}
              buttonStyle={{ border: "1px solid #e4e4e4" }}
              inputStyle={{
                padding: "20px",
                paddingInline: "50px",
                border: "1px solid #e4e4e4",
                width: "100%",
              }}
              name="mobile"
              value={mobile}
              onChange={mobile => setFormData({ ...formData, mobile: getCompleteMobileNumberWithPlus(mobile) })}
            />
          </div>

          <Pincode postOffice={postOffice} setPostOffice={setPostOffice} setPincode={setPincode} />

          {
              <div>
                {/* Complete Address */}
                <div className="mb-3">
                  <label
                    htmlFor="address"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Complete Address
                  </label>
                  <input
                    type="text"
                    // placeholder="Complete Address"
                    name="completeAdd"
                    className="mt-1 p-2 w-full border rounded-md"
                    value={completeAdd}
                    onChange={onChange}
                  />
                </div>

                {/* Building Area Details */}
                <div className="mb-3">
                  <label
                    htmlFor="buildingArea"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Building Area Details
                  </label>
                  <input
                    type="text"
                    // placeholder="Building Area Details"
                    name="buildingDetails"
                    className="mt-1 p-2 w-full border rounded-md"
                    value={buildingDetails}
                    onChange={onChange}
                  />
                </div>

                {/* Floor Details */}
                <div className="mb-3">
                  <label
                    htmlFor="floorDetails"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Floor Details
                  </label>
                  <i className="fab fa-youtube fa-2x" />
                  <input
                    type="text"
                    // placeholder="Floor Details"
                    name="floorDetails"
                    className="mt-1 p-2 w-full border rounded-md"
                    value={floorDetails}
                    onChange={onChange}
                  />
                </div>

                {/* Landmark */}
                <div className="mb-3">
                  <label
                    htmlFor="landmark"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Landmark
                  </label>
                  <input type="text"
                    // placeholder="Landmark (Optional)"
                    name="landmark"
                    className="mt-1 p-2 w-full border rounded-md"
                    value={landmark}
                    onChange={e => onChange(e)}
                  />
                </div>
              </div>
          }

          <div className="flex items-center justify-center">
            <button
              type="button"
              className="bg-black text-white hover:bg-white hover:border-2 hover:border-black hover:text-black transition-all duration-200 py-2 px-4 rounded-md mr-2"
              onClick={() => props.closeAddressModal()}
            >
              Cancel
            </button>

            <button
              type="button"
              className="bg-green-500 text-white py-2 px-4 rounded-md"
              onClick={onAddressSubmit}
            >
              Submit
            </button>
          </div>

        </div>
      </>
    );
  };

  return (
    <>
      {renderAddressForm()}
    </>
  );
};

export default AddressForm;