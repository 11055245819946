import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { GoEye } from "react-icons/go";
import { IoCall, IoCallOutline } from "react-icons/io5";
import { generatePublicImgURL } from '../../urlConfig';
import Carousel from "react-multi-carousel";
import { customDate } from '../../utils/formatDate';
import { ADMIN, ICONS_AND_PHOTOS } from '../aNewGoToUI/utils/constants/s3Constants';
import { checkToShowAddProfessionalDetailsButton } from '../utils/workercategory/WorkerCategory';
import { hasUserOrAdminAccess } from '../utils/common/Common';
import { setProfAvailability } from '../../actions/workerCategory';

const ServiceCardStatus = ({ professional }) => {
    const auth = useSelector((state) => state.auth);
    const [availabilityOption, setAvailabilityOption] = useState(professional?.professionalStatus?.type || "");

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 3,
        },
    };

    const dispatch = useDispatch();

    const setAvailability = (availability, profId) => {
        const availabilityObj = {
            'availability': availability
        };
        dispatch(setProfAvailability(availabilityObj, profId));
    };

    return (
        <div className="flex flex-col gap-3 mx-2 border-2 rounded-sm border-black/10 mb-5 md:mx-[20em]">
            <div className="bg-black/10 rounded-b-full h-[150px] relative">
                {
                    professionalName()
                }

                {
                    paymentStatus()
                }

                {
                    professionalStatusAvailability()
                }

                {
                    applicationStatus()
                }

                {/* <div className="flex justify-around mt-[2.5em]">
                    <button className="bg-green-300 p-3 text-xl border-3 border-white rounded-full active:scale-95 duration-200 transition-all "><GoEye /></button>
                    <button className="bg-green-300 p-3 text-xl border-3 border-white rounded-full active:scale-95 duration-200 transition-all"><IoCall /></button>
                </div> */}

                {
                    profileImage()
                }
            </div>

            {
                serviceAndAddressDetails()
            }


            {
                professionalServiceImages()
            }

            {
                checkToShowAddProfessionalDetailsButton(professional, auth) &&
                addProfessionalDetails()
            }

            {
                hasUserOrAdminAccess(professional, auth) &&
                setAvailabilityAction()
            }
        </div>
    )

    function setAvailabilityAction() {
        return (
            <div className="flex justify-between items-center">
                <select
                    onChange={(e) => setAvailabilityOption(e.target.value)}
                >
                    <option value="">Select Availability</option>
                    <option value="available">Available</option>
                    <option value="unavailable">Unavailable</option>
                </select>

                <div>
                    <button
                        onClick={() => setAvailability(availabilityOption, professional._id)}
                        className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600"
                    >
                        Confirm
                    </button>
                </div>
            </div>
        );
    }

    function addProfessionalDetails() {
        return <div className='blue-gradient w-[14em] md:w-[20em] text-white font-extrabold text-xl py-2 text-center m-auto my-4 rounded-full'>
            <Link
                to={{
                    pathname: `/professional/${professional._id}/details`,
                    state: {
                        properties: professional.properties,
                        servicePropertyFields: professional.choiceOfService?.properties ? professional.choiceOfService.properties : professional.servicePropertyFields,
                        profId: professional._id
                    }
                }}
            >
                Add Professional Details
            </Link>
        </div>;
    }

    function professionalServiceImages() {
        return (
            <div className=" mx-2  ">
                <Carousel
                    swipeable={true}
                    removeArrowOnDeviceType={["mobile"]}
                    dotListClass="mt-"
                    itemClass="carousel-item-padding-40-px mb-[6px] ml-2"
                    responsive={responsive}
                    autoPlay={1000}
                    infinite={true}
                >
                    {professional?.images.length > 0 && (
                        professional?.images.map(image => (
                            <img src={generatePublicImgURL('serviceprofessionals/' + image.img)} alt="professionalImage" className="rounded-md md:w-[10em]" />
                        ))
                    )}
                </Carousel>
            </div>
        );
    }

    function serviceAndAddressDetails() {
        return (
            <div className="text-center text-xs text-black/70 mt-4 p-3 ">
                {serviceAndChoiceDetails()}
                {AddressDetails()}
            </div>)
            ;

        function AddressDetails() {
            return (<span>{professional?.address?.pincodeData.poName}, {professional?.address?.pincodeData.cityBlock}, {professional?.address?.pincodeData.district}, {professional?.address?.pincodeData.stateName}
                ({professional?.address?.pincodeData.pincode})</span>
            );
        }

        function serviceAndChoiceDetails() {
            return (
                <p className="text-center text-sm py-1 my-2 text-black font-bold">
                    {professional?.service}
                    {professional?.choiceOfService !== null ? <span> - {professional?.choiceOfService.name} </span> : null}
                </p>
            );
        }
    }

    function profileImage() {
        return (
            <div className="w-[6em] absolute  -bottom-[5em] left-1/2 transform -translate-x-1/2 -translate-y-1/2   rounded-full border-3 border-white">
                <img
                    src={generatePublicImgURL(ADMIN + '/' + ICONS_AND_PHOTOS + '/' + "profile.png")}
                    alt="profile_image"
                    width={100}
                    height={100} />
            </div>
        );
    }

    function applicationStatus() {
        return <p className="absolute text-xs rounded-full shadow-xs top-1 left-2 py-1 px-2 text-gray-700 border border-gray-300 px-4 ">
            {professional?.status.type === 'active' ?
                <span> Active till {customDate(professional?.status.date, 12)} </span> :
                <>
                    <p>
                        {professional?.status.type.charAt(0).toUpperCase() +
                            professional?.status.type.slice(1)}
                    </p>
                </>}
        </p>;
    }

    function professionalStatusAvailability() {
        return (
            <p className="absolute text-xs rounded-full shadow-xs top-1 right-2 bg-green-300 py-1 px-2 text-gray-700 ">
                {professional?.professionalStatus?.type.charAt(0).toUpperCase()
                    +
                    professional?.professionalStatus?.type.slice(1)}
            </p>
        );
    }

    function paymentStatus() {
        return professional?.paymentStatus === "success" &&
            (
                <p className="absolute text-xs rounded-full shadow-xs top-1 right-2 bg-green-300 py-1 px-2 text-gray-700 ">
                    Payment status : {professional?.paymentStatus}
                </p>
            );
    }

    function professionalName() {
        return (
            <p className="text-center mt-9 font-medium pt-2 text-xl text-black/90">
                {professional?.name}
            </p>
        );
    }
}

export default ServiceCardStatus