import { LOCATIONS } from "../constants/CommonConstants";
import {
    ADVOCATES, AIR_CONDITIONER, ARCHITECTS, BAKERS, BAND_BAJA, BUNGEE_JUMPING, CAFES, CARPENTER, CATERING, CA_CHARTERED_ACCOUNTANT, CCTV_CAMERAS, CHIMNEY, CONCRETE_MIXER, DHOL, DISHWASHER, DJ_SOUND_SYSTEM, ELECTRICIAN,
    FLAT_HOMES, FLEX_PRINTING_SERVICES, GAS_STOVE, GEYSER, GREEN_LEAF_PLATES, GROOMS_CAR, HIMACHALI_DHAM, HOME_STAYS, HOTELS, HOUSE_CONTRACTOR, JCB_BACKHOE, MARBLE_INSTALLER,
    MARRIAGE_BANQUET_HALL, MICROWAVE, MODULAR_KITCHEN, PAINTER, PARAGLIDING, PARTY_HALL, PAYING_GUEST,
    PHOTOGRAPHER,
    PHOTOSTAT, PICK_UP_FOUR_WHEELER_AUTO, PICK_UP_THREE_WHEELER_AUTO, PICK_UP_TRACTOR, PICK_UP_TRANSPORTATION_JEEP, PICK_UP_VANS, PLUMBER, PRINTER_AND_INK_REFILL, PROPERTY_DEALERS, RAJ_MISTRI, REFRIGERATOR, RENTAL_BROKERS, RESTAURANTS, RETAIL_SHOPS, RO_PURIFIER, SHUTTERING, SINGLE_ROOM, TAXIS,
    TAXI_MINI_CAR,
    TAXI_SEDAN_CAR,
    TAXI_TRAVELLER_VAN,
    TAXI_VAN_EECO_VAN,
    TAXI_WINGER_VAN,
    TAXI_XUV_CAR,
    TELEVISION,
    TENT,
    TILE_INSTALLER, TOW_TRUCK, UPVC_ALUMINIUM_DOOR_WINDOW, WASHING_MACHINE, WATER_TANKER, WELDER_METAL_FABRICATOR, WIFI
} from "../constants/DB/ServiceNames";

const serviceLocations = [
    {
        "serviceName": ELECTRICIAN,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": PLUMBER,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": RO_PURIFIER,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": AIR_CONDITIONER,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": REFRIGERATOR,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": CCTV_CAMERAS,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": WIFI,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": TELEVISION,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": WASHING_MACHINE,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": CHIMNEY,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": GEYSER,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": GAS_STOVE,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": DISHWASHER,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": MICROWAVE,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": PRINTER_AND_INK_REFILL,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": PHOTOSTAT,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": PAYING_GUEST,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": SINGLE_ROOM,
        "locations": LOCATIONS,
        "payment": false
    },
    {
        "serviceName": FLAT_HOMES,
        "locations": LOCATIONS,
        "payment": false
    },
    {
        "serviceName": RETAIL_SHOPS,
        "locations": LOCATIONS,
        "payment": false
    },
    {
        "serviceName": TAXI_MINI_CAR,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": TAXI_SEDAN_CAR,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": TAXI_XUV_CAR,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": TAXI_WINGER_VAN,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": TAXI_TRAVELLER_VAN,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": TAXI_VAN_EECO_VAN,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": PICK_UP_TRANSPORTATION_JEEP,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": PICK_UP_THREE_WHEELER_AUTO,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": PICK_UP_TRACTOR,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": PICK_UP_FOUR_WHEELER_AUTO,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": TOW_TRUCK,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": WATER_TANKER,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": ARCHITECTS,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": RAJ_MISTRI,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": TILE_INSTALLER,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": MARBLE_INSTALLER,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": PAINTER,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": CARPENTER,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": WELDER_METAL_FABRICATOR,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": MODULAR_KITCHEN,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": UPVC_ALUMINIUM_DOOR_WINDOW,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": JCB_BACKHOE,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": CONCRETE_MIXER,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": SHUTTERING,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": HOUSE_CONTRACTOR,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": PARTY_HALL,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": MARRIAGE_BANQUET_HALL,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": HIMACHALI_DHAM,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": GREEN_LEAF_PLATES,
        "locations": [
            "Shahpur",
            "Dharmshala",
            "Palampur"
        ],
        "payment": true
    },
    {
        "serviceName": CATERING,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": PHOTOGRAPHER,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": TENT,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": BAND_BAJA,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": DHOL,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": DJ_SOUND_SYSTEM,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": GROOMS_CAR,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": FLEX_PRINTING_SERVICES,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": CA_CHARTERED_ACCOUNTANT,
        "locations": [
            "PAN_INDIA"
        ],
        "payment": true
    },
    {
        "serviceName": ADVOCATES,
        "locations": [
            "PAN_INDIA"
        ],
        "payment": true
    },
    {
        "serviceName": HOME_STAYS,
        "locations": [
            "Dharmshala",
            "Dharmkot",
            "Bir Billing",
            "Jibhi",
            "Kasol",
            "Sissu Atal Tunnal",
            "Spiti",
            "Sangla Chitkul",
            "Reckong Peo",
            "Kalpa",
            "Narkanda",
            "Mashobra",
            "Hatu Peak",
            "Kufri"
        ],
        "payment": true
    },
    {
        "serviceName": HOTELS,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": CAFES,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": RESTAURANTS,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": BAKERS,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": RENTAL_BROKERS,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": PROPERTY_DEALERS,
        "locations": LOCATIONS,
        "payment": true
    },
    {
        "serviceName": PARAGLIDING,
        "locations": [
            "Indrunag (Dharmshala)",
            "Bir",
            "Kullu",
            "Manali"
        ],
        "payment": true
    },
    {
        "serviceName": BUNGEE_JUMPING,
        "locations": [
            "Bir",
            "Manali"
        ],
        "payment": true
    },
    {
        "serviceName": "Test",
        "locations": LOCATIONS,
        "payment": true
    }
]

export const getLocationByServiceNameWeOnboard = (serviceName) => {
    const matchingService = serviceLocations.find(service => service.serviceName === serviceName);

    // If the matching service is found, return its locations; otherwise, return Location array
    return matchingService ? matchingService.locations : LOCATIONS;
};

export const checkPaymentNeededByServiceName = (serviceName) => {
    console.log("payment check", serviceName)
    const paymentNeeded = serviceLocations.find(service => service.serviceName === serviceName);

    // If the matching service is found, return payment enable otherwise, return false
    return paymentNeeded ? paymentNeeded.payment : true;
};